<template xmlns="http://www.w3.org/1999/html">
  <div class="content">
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1d1d1d, black)"
    >

      <vue-whatsapp-widget
        phoneNumber="+524421867547"
        companyName="tulum.party"
        textReply="Typically replies within 10-15 mins (9am - 10pm)"
        companyLogo="https://imgix.cosmicjs.com/08f3cb30-e877-11ec-9177-7506dda64181-2022-06-09-23.37.07.jpg?h=50"
      />
      <section class="text-center col-lg-8 col-sm-12 ">

        <ResponsiveImage :image="mainImage" align="text-center"
                         clamp
                         class="img-animation"
                         text-transform="uppercase"
                         :color="this.secondaryColor"
                         caption="Day Or Night Its Party Time"></ResponsiveImage>


        <hr>
        <h1 class="tulum-party-title"
            style="font-size: 3.5rem;background-color: black">TULUM CLUBS</h1>


        <h4 class="alt-font" style="margin-top: -20px" :style="{backgroundColor: secondaryColor}">Day Or Night Its Party
          Time</h4>

        <hr>


        <p style="font-size: 0.9rem; margin-top: 40px">
          <a
            href="https://www.visitmexico.com/en/quintana-roo/tulum"
            target="_blank"
          >
            Tulum´s</a
          >
          peculiar musical culture can be found everywhere from
          <a
            href="https://zamnafestival.com/"
            rel="nofollow noopener"
            target="_blank"
          >Big festivals</a
          >, <a href="/">outdoor parties</a> and premium events at high-end
          restaurants & clubs. No matter the day nor time, there's always a DJ
          & dancing crowd somewhere in Tulum, waiting for you.
        </p>

        <p class="text-center centered  " style="max-width: 80%;margin-top: 20px">
          We list the <strong>Top Tulum </strong> Clubs you do not want to
          miss this
          <strong class="label label-info font-weight-700">{{
              getCurrentMonth
            }}</strong>
        </p>

        <section>
          <div class="centered text-center">
            <MiniCarrousel
              align="center"

              :captions="clubCaptions"
              :photos="this.clubPhotos"></MiniCarrousel>
          </div>

        </section>


        <section>
          <div class="card text-center" style="border: 2px solid black;margin-top: 30px;padding: 20px">
            <h3
              class="tulum-party-subtitle"
              style="
                text-transform: uppercase;
                font-size: 1.1rem;
                letter-spacing: 0.1rem;
                font-weight: bold;
              "
            >
              <br />
              RESERVE YOU SPOT NOW
            </h3>
            <h5 style="margin-top: -30px">
              Tulum's elite club scene
            </h5>
            <div :style="{backgroundColor: this.thirdColor}">

              <img v-lazy="qrWhats+'?w=300&h=300'" width="300px">

              <br>
            </div>

            <hr>
            <RsvpViaDialog
              legend="

                    <p>Information & Reservations <br> <small>The single source for all events & clubs needs</small></p>"
            ></RsvpViaDialog>
            <br />
            <small style="color: aquamarine">
              We operate from 9AM - 10PM / Answer within 15 mins</small
            >
            <br />
            <small>NO EXTRA FEE</small>
            <br>
            <br>
          </div>


        </section>



        <section class="row">
          <h4 class="section-header alt-font"
              style="font-size: 1.4rem;">
            OPTIONS FOR THE DAY
          </h4>

          <card
            class="card text-center col-sm-12 col-lg-12"
            v-for="(row, idx) in venues"
            :key="idx"
            style="box-shadow: #000000 10px 10px 2px; padding: 40px"
          >
            <template slot="header">

              <router-link :to="{ name: 'Club', params: { slug: row.slug } }">


                <ResponsiveImage :image="row.metadata.main_image.imgix_url"
                                 class="card-animation-on-hover"></ResponsiveImage>

                <h4 class="tulum-party-title" style="margin-top: 20px;font-size: 1.7rem">
                  {{ row.title }}
                </h4>


                <p style="margin-top: -20px;font-size: 0.8rem">
                  {{ clubsConfig[row.slug] ? clubsConfig[row.slug]['caption'] : '' }}</p>

                <hr class="brand-title-black" style="height: 1px" />
              </router-link>
              <div
                v-html="row.metadata.description"
                class="md-small-hide"
                style="color: #fff !important; margin-bottom: 10px"
              ></div>
            </template>

            <template slot="default">
              <router-link
                :to="{ name: 'Club', params: { slug: row.slug } }"
                class="btn font-weight-bold btn-primary"
                style="margin-top: 40px"
              >
                INFO & EVENTS
              </router-link>
            </template>
          </card>

        </section>
      </section>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <SmartMenu :links="links"
                   :primary-color="this.primaryColor"
                   :secondary-color="this.secondaryColor"
                   :logo="this.astroImg" ctaText="DROP US A LINE" top-section-name="TALK TO US">
          <div class="row">
            <div class="col-sm-12 text-center">
              <p>
                <strong>tulum.party</strong><br> The Single Contact for All Clubs & Events<br>
              </p>
              <br>
            </div>
          </div>
        </SmartMenu>
      </aside>
    </article>
  </div>
</template>

<script>

// import clubsTableConfig from '../../common/clubsTableConfig';
import HeadImage from '@/components/HeadImage/HeadImage';
import SmartMenu from '@/components/SmartMenu/SmartMenu';
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';
import clubsConfig from '../../common/clubsConfig';
import MiniCarrousel from '@/components/MiniCarrousel';

const RsvpViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpViaDialog'
    );
const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
    );

export default {
  name: 'Clubs.vue',
  data: () => {
    return {
      showAll: false,
      LOADING_IMAGE:
        'https://imgix.cosmicjs.com/a47ded40-484a-11ee-89ab-17371fc03105-image.png',
      // clubPhotos: [ 'https://imgix.cosmicjs.com/a47ded40-484a-11ee-89ab-17371fc03105-image.png'],
      primaryColor: '#262322',
      clubPhotos: [ "https://imgix.cosmicjs.com/7dd08f80-63fd-11ee-b88a-b3386968dc36-image.png", "https://imgix.cosmicjs.com/3b02f3e0-dbef-11ee-b6bc-0f88b0862d1c-mia-9.jpg", "https://imgix.cosmicjs.com/491a08e0-b45b-11ed-bce9-6ddb530a836d-Bonbonniere-Tulum.jpg", "https://imgix.cosmicjs.com/781690a0-d5ac-11ee-9ce5-59949019255e-gitano-5.jpg", "https://imgix.cosmicjs.com/955959d0-f891-11ec-b2b1-473235369c53-IMG-7616.jpg", "https://imgix.cosmicjs.com/59b06100-5382-11ef-b1ea-f56c65dfade9-article-jungle-brunch-bagatelle.jpg" ],
      secondaryColor: 'rgba(238, 119, 82, 0.5)',
      thirdColor: '#cb9f5f',
      qrWhats: 'https://imgix.cosmicjs.com/2814bd90-ec88-11ef-a238-c1ad09f4b1ad-clubs-qr.png',
      ClubTagsConfig: { tags: [] },
      clubsConfig: [{}],
      astroImg:
        'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      mainImage:
        'https://imgix.cosmicjs.com/7c831370-02b0-11f0-9c94-c1d9b01cc071-78e4e810e3fba8e4051017e27e15419c.webp',
      fire: 'https://imgix.cosmicjs.com/004214c0-02cc-11f0-9c94-c1d9b01cc071-b38e20a1bdb5aecf376b3ba804eed45e.webp',
    };
  },
  metaInfo: () => ({
    title:
      'Tulum Club Reservations & Tickets | Events & VIP Access',
    link: [{ rel: 'canonical', href: 'https://www.tulum.party/tulum-clubs' }],
    meta: [
      {
        name: 'description',
        content:
          'Tulum club tickets & reservations. Find the best nightclubs, beach clubs, & events. Book VIP tables & get tickets for Tulum\'s top parties.'
      },
      {
        name: 'keywords',
        content:
          'tulum nightclubs, tulum beach clubs, tulum nightlife, tulum dj events, tulum party scene, tulum music venues, tulum dance clubs, tulum bars, tulum clubs calendar, tulum events calendar, tulum club reservations, tulum vip tables, tulum bottle service, tulum bachelorette party, tulum bachelor party, tulum vacation activities, tulum things to do at night'
      },
      {
        property: 'og:title',
        content: `Tulum Club Reservations & Tickets | Events & VIP Access`
      },
      { property: 'og:type', content: 'website' },
      { property: 'og:locality', content: 'Tulum' },
      { property: 'og:country-name', content: 'Mexico' },
      {
        property: 'og:description',
        content: 'Tulum club tickets & reservations. Find the best nightclubs, beach clubs, & events. Book VIP tables & get tickets for Tulum\'s top parties.',
      },
      {
        property: 'og:image',
        content:
          'https://imgix.cosmicjs.com/3c6c5b60-96ea-11eb-b593-972a7dbc1054-papaya-playa-small.png'
      }
    ]
  }),
  components: {
    MiniCarrousel,
    ResponsiveImage,
    SmartMenu,
    RsvpViaDialog,
    VueWhatsappWidget
  },
  computed: {
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      }
    },
    // clubPhotos: {
    //   get() {
    //     return this.$store.state.venues.map(x => x.metadata.main_image.imgix_url)
    //   }
    // },
    clubCaptions: {
      get() {
        return this.$store.state.venues.map(x => x.title).slice(0, 6)
      }
    },
    incomingParties: {
      get() {
        return this.$store.state.incomingParties;
      }
    },
    incomingClubParties: {
      get() {
        return this.$store.state.incomingClubParties;
      }
    },
    venues: {
      get() {
        return this.$store.state.venues;
      }
    },
    tags: {
      get() {
        return [
          ...new Set(
            this.$store.state.venues
              .map((v) => v.metadata.tags.split('|'))
              .flat()
          )
        ];
      }
    },
    categories: {
      get() {
        let h = {};
        this.$store.state.venues.forEach((venue) => {
          venue.metadata.tags.split('|').forEach((tag) => {
            if (h[tag]) {
              h[tag].push(venue.title);
            } else {
              h[tag] = [venue.title];
            }
          });
        });
        return h;
      }
      // return this.$store.state.venues.map(x => x.metadata.tags.split("|")).flat().filter((v,i,a) => a.indexOf(v) === i);
    },
    getCurrentMonth: {
      get() {
        let d = new Date();
        return (
          [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ][d.getMonth()] +
          ' ' +
          d.getFullYear()
        );
      }
    },
    sortedVenues: {
      get() {
        return [...this.$store.state.venues].sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      }
    }
  },
  mounted() {
    // alert( Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.$store.dispatch('getVenues', { start: 0, limit: 16 }).then(x => {
      this.$store.dispatch('getLinks', { slug: 'tulum-clubs' });

      // this.ClubTagsConfig = clubsTableConfig;
      this.clubsConfig = clubsConfig;
      // this.clubPhotos = this.$store.state.venues.map(x => x.metadata.main_image.imgix_url).slice(0, 6)

      window.history.scrollRestoration = 'manual';
    });
    // this.$store.dispatch('getParties');

  },
  methods: {
    paginate() {
      this.showAll = true;
      this.$store.dispatch('getVenues', { start: 12, limit: 20 });
    },
    goToClub(event) {
      let path = '';
      if (event.target.value === 'dayClub') {
        path = '/tulum-day-beach-clubs';
      } else {
        path = '/tulum-clubs/' + event.target.value;
      }
      this.$router.push({
        path: path,
        replace: true
      });

      this.$rollbar.info('QUICK CLUB: ' + path);
    }
  }
};
</script>
